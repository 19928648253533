<template>
  <ion-modal
    class="modal-with-layout"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="h-100" style="overflow: auto">
        <form @submit.prevent="submitProfile">
          <div class="py-2">
            <i v-if="!isChatroom"
              ><strong> {{ name }}</strong> is a Social Space! It’s world with chat rooms! Come make some friends!</i
            >
            <i v-else
              ><strong> {{ name }}</strong> is a Private Chatroom! Come make some friends!</i
            >
          </div>
          <div class="py-3">Why do you want to join this {{ isChatroom ? 'Chatroom?' : 'Social Space?' }}</div>
          <ion-textarea v-model="joinForm.text" rows="3" class="c-textarea mb-3" :placeholder="placeHolder" required />

          <div class="w-100 d-flex justify-content-center mt-4">
            <ion-button
              v-if="get(user, 'is_email_verified', true)"
              class="submit-btn"
              :disabled="isSubmitting || !isFormComplete"
              type="submit"
            >
              <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
              <span v-else>Submit</span>
            </ion-button>
            <ion-button
              v-else
              class="submit-btn"
              :disabled="isSubmitting || !isFormComplete"
              @click="openVerificationModal"
            >
              <ChLoading size="sm" v-if="isSubmitting" class="spinner" />
              <span v-else>Submit</span>
            </ion-button>
          </div>
        </form>
      </div>
    </modals-layout>
    <VerificationModal :is-open="verificationModal" @dismiss-modal="closeVerificationModal" />
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { Action } from 's-vuex-class';
import { toast } from '@/shared/native';
import { createWorldCharUserRequest, getMemberstatus } from '@/shared/actions/worlds';
import namespace from '@/shared/store/namespace';
import { joinPrivateSocialChatroom } from '@/shared/actions/socialSpaceChatroom';
import { authStore } from '@/shared/pinia-store/auth';
import VerificationModal from '@/shared/modals/VerificationModal.vue';

@Options({
  name: 'JoinSocialSpace',
  components: { VerificationModal },
})
export default class JoinSocialSpace extends Vue {
  @Prop({ default: () => 'Join Social Space', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;
  @Prop({ default: false }) isChatroom!: boolean;
  @Prop() name!: string;
  @Prop({ required: true }) socialspaceId!: string;
  @Prop({}) chatroomId!: string;

  public get user() {
    const { user } = authStore();
    return user.value;
  }

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public verificationModal = false;

  public openVerificationModal = () => {
    this.verificationModal = true;
  };

  public closeVerificationModal = () => {
    this.verificationModal = false;
  };

  @Action('createWorldRequest', { namespace: namespace.WorldsModule })
  public createWorldRequest!: any;

  public status: any = {};

  isSubmitting = false;
  joinForm = {
    text: '',
  };

  public get isFormComplete() {
    return !!this.joinForm.text;
  }

  public dismissModal() {
    this.$emit('dismissModal');
  }

  public async submitProfile() {
    if (!this.isFormComplete) return;

    this.isSubmitting = true;
    try {
      if (!this.isChatroom) {
        await createWorldCharUserRequest({
          requestee_id: this.user.id,
          requestee_type: 'user',
          world: this.socialspaceId,
          message: this.joinForm.text,
        });
      } else {
        await joinPrivateSocialChatroom(this.chatroomId, this.socialspaceId, this.user.id, 'user', this.joinForm.text);
      }

      if (this.isAuthenticated) {
        const resp = this.isChatroom
          ? await getMemberstatus({ world_id: this.socialspaceId, room_id: this.chatroomId })
          : await getMemberstatus({ world_id: this.socialspaceId });
        this.status = resp;
        this.$emit('status', this.status);
      }

      await toast.show('Request sent successfully', 'nonative', 'success');
    } catch (_err) {
      await toast.show('Some error has occurred. Please try again later.', 'nonative', 'danger');
      this.dismissModal();
    }

    this.dismissModal();
    this.isSubmitting = false;
  }

  public get placeHolder() {
    return 'Help the admins get to know you! You’ll get notified when you’re approved!';
  }
}
</script>

<style lang="sass" scoped>
.submit-btn
  width: 180px
  .spinner
    width: 15px
    height: 15px
</style>
